import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery, Link } from "gatsby"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
const JobSeekers = ({ data }) => {
  const images = data.allFile.nodes

  return (

      <section className="container job-seekers">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="job-seekers__for-job-seekers">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="job-seekers__for-job-seekers--box">
                <GatsbyImage
                  image={getImage(images[0].childImageSharp?.gatsbyImageData)}
                  alt=""
                />
                <p>
                  クイックグローバルメキシコはメキシコのアグアスカリエンテス州を拠点に、メキシコ各地の日系企業でのお仕事を専門に紹介している日系人材紹介会社です。メキシコ国内はもちろん、日本やその他の国からの就職・転職もサポートしておりますので、まずはこちらをご確認ください
                </p>
                <div className="buttons-job-seeker">
                  <Link to="/to-residents-in-mexico">
                    メキシコにお住まいの方へ
                  </Link>
                  <Link to="/to-residents-in-japan-and-other-countries">
                    日本及びその他の国にお住まいの方へ
                  </Link>
                </div>
              </div>
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
   
  )
}

export default JobSeekers

export const queryImage = graphql`
  {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
        relativeDirectory: { eq: "others" }
      }
      sort: { order: ASC, fields: modifiedTime }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 2800
            quality: 100
            formats: [WEBP, AUTO]
            webpOptions: { quality: 100 }
            placeholder: NONE
            transformOptions: { fit: CONTAIN }
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`

export const Head = ({data}) => {
  const images = data.allFile.nodes

  return (
    <Seo
      title="メキシコ求人・就職・転職情報"
      description="クイックグローバルメキシコはメキシコのアグアスカリエンテス州を拠点に、メキシコ各地の日系企業でのお仕事を専門に紹介している日系人材紹介会社です。メキシコ国内はもちろん、日本やその他の国からの就職・転職もサポートしておりますので、まずはこちらをご確認ください"
      url={`https://www.919mexico.com/jobseekers`}
      image={images[0].childImageSharp?.gatsbyImageData.images.fallback.src}
    />
  )
}

